import React from "react";
import { graphql } from "gatsby";

import { ImageDataLike } from "gatsby-plugin-image";

import Layout from "@components/Layout";
import { PostCard } from "@ui";
import Seo from "@components/Seo";

import * as styles from "@styles/pages/index.module.scss";

type Post = {
  slug: string;
  frontmatter: {
    title: string;
    date: string;
    posterImage: ImageDataLike;
    category: string;
  };
  excerpt: string;
};

type Props = {
  data: {
    allMdx: {
      nodes: Post[];
    };
  };
};

const BlogPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <Seo />

      <div className={styles.content}>
        <section>
          {data.allMdx.nodes.map((file, index) => {
            const {
              slug,
              frontmatter: { title, posterImage, category, date },
              excerpt,
            } = file;

            return (
              <PostCard
                key={index}
                poster={posterImage}
                title={title}
                date={date}
                url={`/blog/${slug}`}
                desc={excerpt}
                category={category}
                className={styles.card}
              />
            );
          })}
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { component_id: { eq: "post" } } }
      sort: { fields: frontmatter___date }
    ) {
      nodes {
        slug
        frontmatter {
          title
          posterImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          category
          date(formatString: "MMMM D, YYYY")
        }
        excerpt
      }
    }
  }
`;

export default BlogPage;
